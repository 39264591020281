import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const GuidePage = () => (
    <Layout>
        <SEO title="Guia de Fuentes" />
    </Layout>
)

export default GuidePage
